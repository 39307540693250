/* custom.css */

/* Importing a Google Font - choose a font that suits your style */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Applying the font family */
body, p, a, .btn {
  font-family: 'Poppins', sans-serif;
}

/* Dark Theme Background for the entire app */
body {
  background-color: #121212; /* Dark background */
  color: #fff; /* Light text */
}

/* Purple Highlight Color for links and buttons */
a, .navbar-brand, .nav-link {
  color: #39FF14; /* Adjust the purple shade as needed */
}

/* Headlines Styling */
h1, h2, h3, h4, h5, h6 {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 700; /* Bold weight */
}

main {
  min-height: 90vh;
}

/* Footer Styling */
footer {
  background-color: #1a1a1a; /* Slightly lighter than body for contrast */
  color: #fff;
  padding: 10px 0; /* Small Footer */
}

.btn {
  background-color: black; /* Your primary purple color */
  border-color: #39FF14; /* Match border color */
}

.btn:hover {
  background-color: gray; /* Slightly darker for hover effect */
  border-color: #39FF14;
}

.form-control.text-light.bg-dark::placeholder {
  color: #ccc; /* Light grey color for placeholders */
  opacity: 1; /* Full opacity for placeholders */
}

.scroller {
  display: flex;
  overflow-x: auto;
  padding: 20px;
}

.scroller::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}

.card {
  flex: 0 0 auto; /* Prevents cards from shrinking */
  margin-right: 20px; /* Space between cards */
}

.modal-content {
  background-color: #343a40; /* Dark background for the modal content */
  color: white; /* Light text color */
  padding: 20px;
}